const ijloeIcon = {
    style: {fill:'#87e2fa'},
    d: "m 33.059628,8.723143 c -0.335169,0 -0.638397,0.03742 -0.921391,0.122989 -0.333668,0.03489 -0.638114,0.09996 -0.806669,0.190169 -0.178567,0.09557 -0.52142,0.598307 -0.918289,1.274342 -0.349997,0.522414 -0.712841,1.210521 -1.114145,2.077907 -0.567984,1.14653 -1.072817,2.295451 -1.257289,2.931605 -0.09206,0.229264 -0.159163,0.401534 -0.159163,0.419613 0,0.01841 0.04567,0.04384 0.108521,0.07028 0.112948,0.231804 0.498274,0.374655 1.025261,0.374655 0.824566,0 0.927531,-0.100809 1.491898,-1.462442 0.333401,-0.804389 1.243655,-2.441498 2.023134,-3.638021 l 1.417484,-2.175578 -0.254249,-0.01964 c 6.32e-4,-0.003 0.0088,-0.01653 0.0088,-0.0186 0,-0.08075 -0.28956,-0.147277 -0.643887,-0.147277 z " +
       "m -5.243608,0.01757 c -0.144232,-0.0041 -0.330774,0.012 -0.527616,0.03617 -0.0062,-1.1e-5 -0.01135,-5.16e-4 -0.01757,-5.16e-4 -0.6609,0 -1.041696,0.0831 -1.41025,0.352433 -0.105244,0.0467 -0.193025,0.09569 -0.252696,0.145211 -0.06534,0.05422 -0.166436,0.192305 -0.289388,0.38344 -1.132589,1.373198 -3.155087,5.785246 -2.84789,6.282303 0.282591,0.457242 1.977321,0.283745 2.131136,-0.218075 0.354521,-1.156607 1.315892,-2.977605 2.510441,-4.755782 0.71492,-1.064207 1.299663,-1.991939 1.299663,-2.06189 0,-0.03121 -0.113171,-0.05894 -0.282152,-0.08113 -0.03777,-0.05085 -0.149495,-0.07747 -0.313677,-0.08217 z " +
       "m 10.787973,0.544671 c -0.08519,-0.0031 -0.19153,0.0079 -0.309026,0.02481 -0.03639,-7.67e-4 -0.06807,-0.0047 -0.10542,-0.0047 -0.793052,0 -1.244248,0.174027 -1.741496,0.671277 -0.733949,0.733948 -2.653575,4.629126 -2.907831,5.900414 -0.144611,0.723059 -0.08978,0.804535 0.597378,0.890384 1.131134,0.141314 1.259073,0.04496 1.742528,-1.31568 0.244589,-0.688375 1.104828,-2.255227 1.91203,-3.481959 0.807201,-1.22673 1.467609,-2.328058 1.467609,-2.447396 0,-0.0675 -0.163171,-0.125489 -0.403593,-0.165365 -0.05644,-0.04411 -0.139599,-0.0678 -0.252179,-0.07183 z " +
       "m -16.513723,0.0124 c -0.02823,0.0017 -0.06186,0.0085 -0.09147,0.01137 -0.06289,-7.59e-4 -0.114316,-0.0036 -0.185002,-0.0036 -1.469742,0 -2.141429,0.707434 -3.588412,3.779615 -1.494848,3.173809 -1.533104,3.436916 -0.529166,3.639052 0.436562,0.0879 0.871884,0.121891 0.967382,0.07493 0.0955,-0.04696 0.531818,-0.850741 0.969452,-1.785937 0.437632,-0.935202 1.284976,-2.420848 1.883087,-3.301609 1.196301,-1.761627 1.495169,-2.234067 1.01441,-2.360062 -0.09728,-0.04883 -0.251211,-0.06513 -0.440285,-0.05374 z " +
       "m 22.032764,1.041797 c -0.02463,8.55e-4 -0.05368,0.0053 -0.07906,0.0067 -0.05235,0.002 -0.109101,0.008 -0.166397,0.01395 -0.637381,0.05765 -1.417674,0.248346 -1.745115,0.505912 -0.622019,0.489281 -3.02932,5.196134 -3.189467,6.236312 -0.06061,0.393655 0.11344,0.559212 0.71365,0.680061 0.436563,0.0879 0.871884,0.121891 0.967383,0.07493 0.0955,-0.04696 0.542787,-0.873953 0.993738,-1.837616 0.45095,-0.96366 1.355079,-2.557481 2.009179,-3.541384 0.654098,-0.983906 1.189075,-1.861733 1.189075,-1.951302 0,-0.14655 -0.294053,-0.201401 -0.692981,-0.187587 z " +
       "M 16.70352,10.621735 c -0.581366,-0.01005 -1.445643,0.212241 -1.920814,0.58601 -0.09309,0.07323 -0.215072,0.215789 -0.357084,0.409795 -1.7e-4,1.88e-4 -3.47e-4,3.28e-4 -5.16e-4,5.16e-4 -0.199874,0.220755 -0.390991,0.5041 -0.613915,0.885219 -0.19372,0.331184 -0.397651,0.720116 -0.600481,1.123444 -0.865151,1.587418 -1.723833,3.434967 -1.72806,3.929476 -0.0024,0.284154 0.259324,0.48573 0.72347,0.55604 0.400182,0.06062 0.813557,0.128061 0.91829,0.14986 0.104734,0.0218 0.595759,-0.838232 1.091408,-1.910993 0.495646,-1.072766 1.392475,-2.673622 1.992643,-3.557407 0.600165,-0.883783 1.090888,-1.732167 1.090888,-1.885673 0,-0.190304 -0.24701,-0.280257 -0.595829,-0.286287 z " +
       "m 10.447425,7.443472 c -9.048089,-0.0029 -18.037494,2.249958 -22.370211,5.821349 -1.909604,1.574059 -2.643823,4.372854 -1.651062,6.292641 1.768449,3.419803 8.948466,6.465983 17.741552,7.526671 1.85575,0.223856 10.513713,0.152233 12.574944,-0.103868 8.547121,-1.061971 15.415244,-4.100195 17.04857,-7.542175 0.958355,-2.019588 0.325342,-4.495938 -1.575612,-6.165001 -2.958524,-2.597615 -9.931681,-4.922861 -16.764871,-5.590354 -1.650415,-0.161222 -3.327738,-0.238723 -5.00331,-0.239263 z " +
       "m 3.872113,2.088245 c 0.0521,0.01999 0.101465,0.0423 0.148313,0.06615 l 0.64027,0.686263 c 0.628579,0.674124 3.432889,5.591709 3.820438,6.699332 0.247078,0.706149 -2.915304,6.676707 -4.173905,8.009331 -0.193522,0.147495 -0.408432,0.228733 -0.69918,0.288354 -0.748451,0.06969 -2.299222,0.120391 -4.012676,0.121957 -3.988716,0.0039 -4.206598,-0.02339 -4.77697,-0.593762 -0.80586,-0.80586 -3.876249,-6.599558 -3.876249,-7.314282 0,-0.961109 3.171372,-6.6793 4.210595,-7.658449 0.06226,-0.04379 0.127764,-0.08336 0.19482,-0.121438 0.362916,-0.09801 2.254589,-0.177253 4.418336,-0.179319 z " +
       "m 4.242636,0.614434 c 0.578485,0.01626 1.581592,0.24716 3.129524,0.641302 4.03493,1.027393 8.307168,3.005418 9.551355,4.422471 1.446662,1.64764 1.441656,2.926255 -0.01757,4.36149 -1.719528,1.691256 -6.325032,3.851288 -9.917743,4.651396 -0.986771,0.219757 -2.033188,0.452067 -2.325438,0.516247 -0.292251,0.06418 -0.690214,0.0184 -0.884701,-0.101801 -0.272865,-0.168641 0.0447,-0.935202 1.391129,-3.356385 0.959681,-1.725729 1.744596,-3.411567 1.744596,-3.746543 0,-0.121787 -0.04868,-0.316658 -0.118856,-0.542083 -0.0038,-0.02393 -0.0059,-0.04957 -0.01137,-0.07183 -0.02356,-0.09552 -0.09003,-0.264245 -0.190172,-0.48731 -3.07e-4,-6.85e-4 -7.22e-4,-0.0019 -0.001,-0.0026 -0.429181,-1.072613 -1.25349,-2.679319 -2.253094,-4.339788 -0.227846,-0.378484 -0.405177,-0.691685 -0.530717,-0.953429 -0.02031,-0.04242 -0.03828,-0.08074 -0.05684,-0.120407 -0.27464,-0.623387 -0.172606,-0.889404 0.490926,-0.870746 z " +
       "m -16.832048,0.02325 c 0.270452,-0.0076 0.432039,0.02896 0.510048,0.106969 0.208024,0.208023 -0.08293,0.936799 -1.044381,2.616377 -1.749984,3.05707 -2.136294,3.840128 -2.178678,4.48655 -0.0015,0.0098 -0.0088,0.02958 -0.0088,0.03669 0,0.004 0.0047,0.01582 0.0052,0.02067 -8.63e-4,0.02428 -0.0052,0.04972 -0.0052,0.0739 0,0.328234 0.773906,1.985447 1.719791,3.682463 0.945886,1.697016 1.719792,3.14815 1.719792,3.224609 0,0.937729 -6.664542,-0.836446 -10.371976,-2.761072 -2.428694,-1.260798 -4.180107,-3.003704 -4.180107,-4.16047 0,-2.490171 4.762243,-5.360987 11.459765,-6.908104 1.170763,-0.270444 1.923783,-0.405932 2.374532,-0.418579 z " +
       "m 8.376751,2.928501 c -0.357648,0.0034 -0.725977,0.05257 -1.100709,0.15348 -2.626048,0.707122 -3.991478,3.441374 -2.91765,5.842021 0.617934,1.381455 1.273072,2.005107 2.62568,2.499071 1.707282,0.623494 3.661926,0.03956 4.936648,-1.475361 2.373779,-2.821072 -0.08672,-7.051874 -3.543969,-7.019211 z",
}

export default ijloeIcon
