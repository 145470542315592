import { initializeApp } from 'firebase/app';

import { auth } from 'firebaseui';
import { getAuth, signInWithRedirect, getRedirectResult, GoogleAuthProvider, connectAuthEmulator } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator, doc, getDoc, setDoc } from 'firebase/firestore';
import firebaseConfig from './firebaseCfg';
import { loginSuccess, logoutSuccess } from 'store/reducers/account';
import {UR} from './types';

const provider = new GoogleAuthProvider();

let app;
let cachedUser;
let loginDispatch;

const setupAuth = (dispatch) => loginDispatch = dispatch;

const unloadAuth = async () => {
  const authorization = getAuth();
  try {
    await authorization.signOut();
    loginDispatch(logoutSuccess());
  } catch (signoutErr) {
    console.error('Error Logging Out ... ', signoutErr);
  }
}

const loadAuth = async () => {
  const authorization = getAuth();
  try {
    const authResult = await getRedirectResult(authorization)

    // This gives you a Google Access Token. You can use it to access Google APIs.
    const credential = GoogleAuthProvider.credentialFromResult(authResult);
    const token = credential.accessToken;

    // The signed-in user info.
    const user = authResult.user;
    console.debug('User signed in:', token, user);
    loginDispatch(loginSuccess(user.bnphtz));
    cachedUser = {...user};
    return authResult;
  } catch (error) {
    // Handle Errors here.
    const errorCode = error.code;
    const errorMessage = error.message;
    // The email of the user's account used.
    const email = error.email;
    // The AuthCredential type that was used.
    const credential = GoogleAuthProvider.credentialFromError(error);
    console.error('Error while signing in...', errorCode, errorMessage, email, credential)
  }

  await signInWithRedirect(authorization, provider);


  const ui = new auth.AuthUI(authorization);

  ui.start('#firebaseui-auth-container', {
    signInOptions: [
      // List of OAuth providers supported.
      GoogleAuthProvider.PROVIDER_ID,
    ],
    // Other config options...
  });
}

const initFirebase = async () => {
  if (app) {
    console.log('Ij-Loe is already initialized.');
    return;
  }
  app = initializeApp(firebaseConfig);
  // const analytics = getAnalytics(app);
  const authorization = getAuth();

  const db = getFirestore(app);
  if (process.env.NODE_ENV !== 'production') {
    console.debug('using the emulator');
    connectAuthEmulator(authorization, 'http://localhost:9299');
    connectFirestoreEmulator(db, 'localhost', 8280);
  }

  authorization.onAuthStateChanged(async (user) => {
    if (user) {
      const permz = await getDoc(doc(db, 'users', user.uid));
      if(!permz.exists()) {
        await setDoc(doc(db, 'users', user.uid), {
          uid: user.uid,
          name: user.displayName,
          role: UR.STUDENT,
        })
      }
      user.bnphtz = (permz.data() || {});
      if(loginDispatch) {
        loginDispatch(loginSuccess(user.bnphtz));
      }
      cachedUser = {...user};
    } else {
      console.error('Error: missing user')
    }
  })

  return authorization;
}


initFirebase().then(() => console.debug('Application Initialized'));

const getApp = () => app;

const getCachedUser = () => cachedUser;

export {
  getApp,
  getCachedUser,
  initFirebase,
  loadAuth,
  unloadAuth,
  setupAuth,
}