import React, { useEffect } from 'react';
import Routes from './routes';
import { BrowserRouter } from 'react-router-dom';

import { setupAuth } from './firebase';
import {useAppDispatch, useAppSelector} from "./store/hooks";
import {User} from "./types";
import './App.css';

function App() {
  const dispatch = useAppDispatch();
  const {user} = useAppSelector((state) => state.user);

  useEffect(() => {
    const getData = async (u: User | undefined) => {
      console.log('new user?', u)
    }

    getData(user).then(() => console.log('Data Loaded'));
  }, [dispatch, user]);

  useEffect(() => {
    setupAuth(dispatch);
  })

  return (
      <BrowserRouter basename="/">
        <Routes />
      </BrowserRouter>
  );
}

export default App;
