const firebaseConfig = {
  apiKey: "AIzaSyDbsvQ4jiSg9TuQf1bHMFPWjcdDKuQb4x0",
  authDomain: "ij-loe.wonderlutz.com",
  projectId: "ij-loe-518bb",
  storageBucket: "ij-loe-518bb.appspot.com",
  messagingSenderId: "268988378581",
  appId: "1:268988378581:web:f0ab651369acf43eaa615c",
  measurementId: "G-01YMC1LB0Z"
};

export default firebaseConfig;