import { Outlet } from 'react-router-dom';
// material-ui
import Box from '@mui/material/Box';

// project import
import Header from './Header';

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => (
  <Box sx={{ display: 'flex', width: '100%', pt: 0 }}>
    <Header />
    {/*<Header open={open} handleDrawerToggle={handleDrawerToggle} />*/}
    {/*<Drawer open={open} handleDrawerToggle={handleDrawerToggle} />*/}
    <Box component="main" sx={{ width: '100%', flexGrow: 1, px: { xs: 2, md: 3 }, pt: { xs: 0, md: 3 } }}>
      {/*<Breadcrumbs navigation={navigation} title titleBottom card={false} divider={false} />*/}
      <Outlet />
    </Box>
  </Box>
);

export default MainLayout;
