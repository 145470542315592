import { lazy } from 'react';
import { Navigate } from 'react-router';
// project import
import Loadable from 'components/Loadable';
import MainLayout from './layout';

// render - dashboard
const IjLoe = Loadable(lazy(() => import('pages/ij-loe')));
const Educator = Loadable(lazy(() => import('pages/educator')));
const Welcome = Loadable(lazy(() => import('pages/welcome')));
const Legal = Loadable(lazy(() => import('pages/legal')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout/>,
    children: [
        {
            path: '/flash-cards/:id?',
            element: <IjLoe numCards={2}/>
        },
        {
            path: '/print/:id?',
            element: <IjLoe numCards={57}/>
        },
        {
            path: '/educator/:id?',
            element: <Educator />
        },
        {
            path: '/welcome',
            element: <Welcome />
        },
        {
            path: '/legal',
            element: <Legal />
        },
        {
            path: '*',
            element: <Navigate to='welcome' replace />
        },
        {
            path: '',
            index: true,
            element: <Navigate to='welcome' replace />
        },
    ]
};

export default MainRoutes;
