import React, {useRef, useState} from 'react';
import {useSelector} from "react-redux";
import {dispatch, RootState} from "../store";
import {loginRequest, logoutRequest} from "../store/reducers/account";
import {useTheme} from "@mui/material/styles";

// assets
import LoginOutlined from "@mui/icons-material/LoginOutlined";
import LogoutOutlined from "@mui/icons-material/LogoutOutlined";
import AccountCircleOutlined from "@mui/icons-material/AccountCircleOutlined";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import ButtonBase from '@mui/material/ButtonBase';
import List from '@mui/material/List';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Transitions from './Transitions2';
import {SchoolOutlined} from "@mui/icons-material";
import {useNavigate} from "react-router";
import {User} from "../types";

// ==============================|| HEADER PROFILE - PROFILE TAB ||============================== //

const AccountButton = () => {
  const theme = useTheme();
  const {user}: {user: User | undefined} = useSelector((state: RootState) => state['user']);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleLogin = async () => {
    dispatch(loginRequest());
  };

  const handleLogout = async () => {
    setOpen(false);
    dispatch(logoutRequest());
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleEducatorRequest = () => {
    setOpen(false);
    navigate('/register');
  };

  const iconBackColorOpen = 'grey.300';

  return (
    <Box sx={{flexShrink: 0, ml: 0.75}}>
      <ButtonBase
        sx={{
          p: 1,
          bgcolor: open ? iconBackColorOpen : 'transparent',
          borderRadius: 1,
          '&:hover': {bgcolor: 'secondary.lighter'}
        }}
        aria-label={user ? 'Logout' : 'Login'}
        ref={anchorRef}
        aria-controls={open ? 'library-grow' : undefined}
        aria-haspopup="true"
        onClick={user ? handleToggle : handleLogin }
      >
        {user ? (
          <AccountCircleOutlined sx={{fontSize: '2rem', color: open ? 'black' : 'inherit'}}/>
          ) : (
          <LoginOutlined sx={{fontSize: '2rem', color: open ? 'black' : 'inherit'}}/>
          )}
      </ButtonBase>
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 14]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={open} {...TransitionProps}>

            {open && (
              <Paper
                sx={{
                  boxShadow: theme.shadows[1],
                  width: 290,
                  minWidth: 240,
                  maxWidth: 290,
                  [theme.breakpoints.down('md')]: {
                    maxWidth: 250
                  }
                }}
              >

                <List component="nav" sx={{
                  p: 0,
                  '& .MuiListItemIcon-root': {minWidth: 32, color: theme.palette.grey[500]}
                }}>
                  { user!.role !== 'educator' && <ListItemButton onClick={handleEducatorRequest}>
                    <ListItemAvatar>
                      <Avatar
                        sx={{
                          color: 'success.main',
                          bgcolor: 'success.lighter'
                        }}
                      >
                        <SchoolOutlined />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Request Educator Account"/>
                  </ListItemButton> }
                  <ListItemButton onClick={handleLogout}>
                    <ListItemAvatar>
                      <Avatar
                        sx={{
                          color: 'success.main',
                          bgcolor: 'success.lighter'
                        }}
                      >
                        <LogoutOutlined />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Logout"/>
                  </ListItemButton>
                </List>

              </Paper>
            )}
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default AccountButton;
