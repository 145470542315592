import React from 'react';

// assets
import Box from "@mui/material/Box";
import ButtonBase from '@mui/material/ButtonBase';
import SvgIcon from '@mui/material/SvgIcon';
import ijloe from './ijloe';

// ==============================|| HEADER PROFILE - PROFILE TAB ||============================== //

const IjLoeIcon = () => {

  return (
    <Box sx={{flexShrink: 0, ml: 0.75}}>
      <ButtonBase
        sx={{
          p: 1,
          bgcolor: 'transparent',
          borderRadius: 1,
          '&:hover': {bgcolor: 'secondary.lighter'}
        }}
        aria-label="Ij-Loe Icon"
      >
        <SvgIcon style={ijloe.style} sx={{fontSize: '3rem', color: 'inherit'}} viewBox='0 0 52 52'>
          <path d={ijloe.d} />
        </SvgIcon>
      </ButtonBase>
    </Box>
  );
};

export default IjLoeIcon;
