export enum UR {
    STUDENT = 'student',
    EDUCATOR = 'educator',
    ADMIN = 'admin',
}

interface translates {
    lang1: string,
    lang2: string,
}

interface wordlist {
    list: translates[],
}

interface wordLoc {
    card: number,
    word: number,
    seven: number,
}

interface Pos {
    x: number,
    y: number,
}

interface LibraryEntry {
    flashId: string,
    flashcard: string,
}

interface CardDocument {
    flashId: string
    list: translates[],
    name: string,
    owner?: string,
}

type UserRoles = UR.STUDENT | UR.EDUCATOR | UR.ADMIN

interface User {
    uid: string,
    name: string,
    role: UserRoles,
}
export enum Lado {
  LANG1 = 'lang1',
  LANG2 = 'lang2',
}

export enum LadoAlg {
  RANDOMIZED = 'rand',
  ONLINE = 'online',
  PRINT = 'print',
}

export type {
  translates,
  wordlist,
  Pos,
  wordLoc,
  LibraryEntry,
  CardDocument,
  User,
}