// third-party
import { configureStore } from '@reduxjs/toolkit';

// project import
import reducer from './reducers';

// ==============================|| REDUX TOOLKIT - MAIN STORE ||============================== //

const store = configureStore({reducer});

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
export type AppStore = typeof store.getState
export type RootState = ReturnType<AppStore>

const { dispatch } = store;

export { store, dispatch, reducer };
