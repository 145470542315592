// types
import { createSlice } from '@reduxjs/toolkit';
import {loadAuth, unloadAuth} from '../../firebase';
import { User } from '../../types';

interface AccountState {
  isLoggingIn: boolean;
  isLoggingOut: boolean;
  user: User | undefined;
}

// initial state
const initialState: AccountState = {
  isLoggingIn: false,
  isLoggingOut: false,
  user: undefined,
};

// ==============================|| SLICE - MENU ||============================== //

const account = createSlice({
  name: 'account',
  initialState,
  reducers: {
    loginSuccess(state, action) {
      console.log('this is our reducer', state, action);
      state.isLoggingIn = false;
      state.user = {
        name: action.payload.name,
        uid: action.payload.uid,
        role: action.payload.role,
      };
    },

    logoutSuccess(state) {
      state.isLoggingOut = false;
      state.user = undefined;
    },

    loginRequest(state) {
      state.isLoggingIn = true;
      loadAuth();
    },

    logoutRequest(state) {
      state.isLoggingOut = true;
      unloadAuth();
    }
  }
});

export default account.reducer;

export const { loginSuccess, logoutSuccess, loginRequest, logoutRequest } = account.actions;
