import React, {useRef, useState} from 'react';
import { useSessionStorage } from 'usehooks-ts';
import {useNavigate} from 'react-router';

// material-ui
import { useTheme } from '@mui/material/styles';

// assets
import Style from '@mui/icons-material/Style';
import Box from "@mui/material/Box";
import ButtonBase from '@mui/material/ButtonBase';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Transitions from './Transitions';


// ==============================|| HEADER PROFILE - PROFILE TAB ||============================== //

const FlashCardLibrary = () => {
  const theme = useTheme();
  const [library] = useSessionStorage('library', []);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleListItemClick = (flashcard) => {
    if (flashcard) navigate(`/flash-cards/${flashcard}`);
  };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const iconBackColorOpen = 'grey.300';

  return (
    <Box sx={{flexShrink: 0, ml: 0.75}}>
      <ButtonBase
        sx={{
          p: 1,
          bgcolor: open ? iconBackColorOpen : 'transparent',
          borderRadius: 1,
          '&:hover': {bgcolor: 'secondary.lighter'}
        }}
        aria-label="open flash card library"
        ref={anchorRef}
        aria-controls={open ? 'library-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Style sx={{fontSize: '2rem', color: open ? 'black' : 'inherit'}}/>
      </ButtonBase>
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 14]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={open} {...TransitionProps}>

          {open && (
            <Paper
              sx={{
                boxShadow: theme.shadows[1],
                width: 290,
                minWidth: 240,
                maxWidth: 290,
                [theme.breakpoints.down('md')]: {
                  maxWidth: 250
                }
              }}
            >

              <List component="nav" sx={{
                p: 0,
                '& .MuiListItemIcon-root': {minWidth: 32, color: theme.palette.grey[500]}
              }}>
                {library.map(({flashId, flashcard}) => (
                  <ListItemButton key={flashId} onClick={() => handleListItemClick(flashId)}>
                    <ListItemText primary={flashcard}/>
                  </ListItemButton>
                  )
                )}
              </List>

            </Paper>
          )}
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default FlashCardLibrary;
