
// material-ui
// import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
// import useMediaQuery from '@mui/material/useMediaQuery';

// project import
import Box from '@mui/material/Box';

// project import
import IjLoeIcon from "../../components/IjLoeIcon";
import FlashCardLibrary from "../../components/FlashCardLibrary";
import AccountPopout from "../../components/AccountPopout";
import EducatorNavigator from "../../components/EducatorNavigator";

// ==============================|| MAIN LAYOUT - HEADER ||============================== //

const Header = () => {
    // const theme = useTheme();
    // const matchesXs = useMediaQuery(theme.breakpoints.down('md'));

    // const iconBackColor = 'grey.100';
    // const iconBackColorOpen = 'grey.200';

    // common header
    const mainHeader = (
        <Toolbar>
          <IjLoeIcon />
            {/*{!matchesXs && <Search />}*/}
            {/*{matchesXs && <Box sx={{ width: '100%', ml: 1 }} />}*/}
          <Box sx={{flexGrow: 1}}  />
          <EducatorNavigator />
          <FlashCardLibrary />
          <AccountPopout />
            {/*{!matchesXs && <Profile />}*/}
            {/*{matchesXs && <MobileSection />}*/}
        </Toolbar>
    );

    // app-bar params
    // const appBar = {
    //     position: 'fixed',
    //     color: 'inherit',
    //     elevation: 0,
    //     sx: {
    //         borderBottom: `1px solid ${theme.palette.divider}`,
    //         height: '30px'
    //         // boxShadow: theme.customShadows.z1
    //     }
    // };
    // <AppBar {...appBar}>{mainHeader}</AppBar>

    return (
        <>
            <AppBar className='App-header' color='primary'>{mainHeader}</AppBar>
        </>
    );
};

export default Header;
