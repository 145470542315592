// third-party
import { combineReducers } from 'redux';

// project import
import user from './account';
import card from './cards';
// import menu from './menu';
// import uc from './uc';
// import admin from './admin';
// import cost from './cost';
// import packages from './packages';

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  user,
  card,
  // menu,
  // uc,
  // admin,
  // cost,
  // packages,
});

export default reducers;
