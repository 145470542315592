// types
import { createSlice } from '@reduxjs/toolkit';
import {CardDocument, wordlist} from "../../types";

type wordlistObj = {
  [key: string]: wordlist,
};

interface CardState {
  cardsLoaded: boolean;
  cardsLoading: boolean;
  needsSaving: boolean;
  cards: wordlistObj,
  cardArray: CardDocument[],
}

// initial state
const initialState: CardState = {
  cardsLoaded: false,
  cardsLoading: true,
  needsSaving: false,
  cards: {},
  cardArray: [],
};

// The cards look like this:
//  {
//    B: { B_1: "0", B_2: "1000" },
//    HIP: { HIP_1: "0", HIP_2: "1500" },
//    x4k: { x4k_1: "0", x4k_2: "750" },
//  }

// ==============================|| SLICE - MENU ||============================== //

const card = createSlice({
  name: 'card',
  initialState,
  reducers: {
    loadCards(state, action) {
      state.cardsLoaded = true;
      state.cardsLoading = false;
      state.cards = {...state.cards, [action.payload.id]: action.payload.wordlist};
      return state;
    },
    loadingCards(state) {
      state.cardsLoading = true;
      return state;
    },
    loadAllCards(state, action) {
      state.needsSaving = true;
      state.cardsLoaded = true;
      state.cardsLoading = false;
      state.cards = action.payload.reduce(
        (newCards: wordlistObj, card: CardDocument) => ({
          ...newCards,
          [card.flashId]: {
            list: card.list
          }
        }), {} as wordlistObj);
      state.cardArray = [...action.payload];
      return state;
    },
  }
});

export default card.reducer;

export const {
  loadCards,
  // loadingCards,
  loadAllCards,
  // saveCard,
} = card.actions;