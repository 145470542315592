import React from 'react';

// assets
import Box from "@mui/material/Box";
import ButtonBase from '@mui/material/ButtonBase';
import SchoolOutlined from "@mui/icons-material/SchoolOutlined";
import {useNavigate} from "react-router";
import {useAppSelector} from "../store/hooks";

// ==============================|| HEADER PROFILE - PROFILE TAB ||============================== //

const EducatorNavigator = () => {
  const {user} = useAppSelector((state) => state.user);
  const navigate = useNavigate();


  return (<>
    {user?.role === 'educator' ? (
      <Box sx={{flexShrink: 0, ml: 0.75}}>
        <ButtonBase
          sx={{
            p: 1,
            bgcolor: 'transparent',
            borderRadius: 1,
            '&:hover': {bgcolor: 'secondary.lighter'}
          }}
          aria-label={'Educator\'s Page'}
          // ref={anchorRef}
          aria-controls={undefined}
          aria-haspopup="true"
          onClick={() => navigate('/educator')}
        >
          <SchoolOutlined sx={{fontSize: '2rem', color: 'inherit'}}/>
        </ButtonBase>
      </Box>
    ) : null}
  </>);
};

export default EducatorNavigator;
